body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overscroll-behavior-x: none;
}

.react-grid-layout {
  position: relative;
}
